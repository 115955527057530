<template lang="pug">
article.delivery
  .delivery__cont.cont
    h1.delivery__caption.caption Доставка
    p.delivery__text.text-big
      | Рассчитываем стоимость разных вариантов доставки и подбираем самый выгодный, работаем только с проверенными
      | перевозчиками. Стоимость доставки можно включить в счет или оплатить по факту получения товара
    ul.delivery__list
      li.delivery__item
        h3.delivery__headline.headline Самовывоз
        p.delivery__info.text
          | Со склада в Москве, если товар в наличии. Сормовский проезд, 7А. Пн-Чт: 9-17, Пт: 9-16
      li.delivery__item
        h3.delivery__headline.headline Курьером по Москве
        p.delivery__info.text
          | Стоимость и время доставки или отгрузки согласовывается с менеджерами
      li.delivery__item.delivery__item_big
        h3.delivery__headline.headline Транспортными компаниями по России и СНГ
        p.delivery__info.text
          | Отправляем товары во все города России транспортными компаниями ПЭК, Деловые линии, СДЭК, DPD и др.
    h2.delivery__title.title Упаковка
    p.delivery__text.text-big Тщательно упаковываем все товары, чтобы вы получили заказ в полной сохранности
    .delivery__video-box
      iframe.delivery__video(
        src="https://www.youtube.com/embed/minPe0E3h6w"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      )
</template>

<script>
import '@/assets/styles/components/delivery.sass'

export default {
  name: 'delivery',
  emits: ['show-modal'],
  data () {
    return {
      controls: ['zoomControl'],
      behaviors: ['drag', 'multiTouch', 'dblClickZoom', 'scrollZoom'],
      shops: []
    }
  },
  computed: {
    zoom () {
      if (window.innerWidth > 1240) {
        return 12
      } else if (window.innerWidth > 950) {
        return 11
      } else {
        return 10
      }
    }
  }
}
</script>
