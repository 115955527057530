<template lang="pug">
head-meta(title-page="Доставка мебели из интернет магазина ChiedoHome",
  description="Способы доставки мебели на дом или в транспортную компанию. Онлайн заказ в интернет магазине chiedohome")
delivery(@show-modal="openModal")
//modal-phone(:is-show="showModal", @close-modal="hideModal")
</template>

<script>
import HeadMeta from '../components/_layout/HeadMeta'
import Delivery from '../components/delivery/Delivery'
import ModalPhone from '../components/_modals/ModalPhone'

export default {
  name: 'DeliveryPage',
  components: { HeadMeta, ModalPhone, Delivery },
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    openModal () {
      this.showModal = true
    },
    hideModal () {
      this.showModal = false
    }
  }
}
</script>
