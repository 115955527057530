<template lang="pug">
.modal-phone(v-if="isShow")
  .modal-phone__inner
    .modal-phone__layer(@click="eventCloseModal")
    .modal-phone__cont
      .modal-phone__box
        .modal-phone__close(@click="eventCloseModal")
        h2.modal-phone__title.title Узнайте стоимость доставки
        p.modal-phone__text.text-big Оставьте заявку, и наш менеджер рассчитает стоимость доставки
        form.modal-phone__form
          input(type="hidden", name="Report_on_stock[is_spam]")
          input(type="hidden", name="form_name" value="")
          input.modal-phone__input.input.text(placeholder="Телефон" name="phone" type="tel" required)
          button.modal-phone__submit.text.button-orange Отправить заявку
</template>

<script>
import '@/assets/styles/components/modal-phone.sass'

export default {
  name: 'ModalPhone',
  props: {
    isShow: {
      type: Boolean
    }
  },
  emits: ['close-modal'],
  methods: {
    eventCloseModal () {
      this.$emit('close-modal')
    }
  }
}
</script>
